<template>
  <li class="collapsibleGroup" :class="{ locked: isLocked }">
    <div class="material-viewer-tile" @click="openGroup">
      <div class="material-viewer-tile__left">
        <b-icon-chevron-right class="chevron-icon" :class="{ active: visible }" />
        <FolderIcon :active="!isLocked" />
        <div class="collapsibleGroup__name">
          <span>{{ item.activity.name || $t('label.groupType') }}</span>
          <span>{{ item.activity.description || $t('label.newGroup') }}</span>
        </div>
      </div>
      <div class="material-viewer-tile__right status">
        <div class="status__icon">
          <transition name="component-fade" mode="out-in">
            <component :is="computedIcon" :key="item.activity.statistic.status" />
          </transition>
        </div>
      </div>
    </div>
    <b-collapse :id="`collapse-${item.activity._id}`" v-model="visible">
      <div v-if="groupContent.length" class="collapsibleGroup__items">
        <component
          v-for="(item, index) in groupContent"
          :is="computeComponent(item)"
          :item="computeItem(item)"
          :index="index"
          :class="{ active: isActive(item) }"
          :key="item.activity._id"
          :selectMaterial="selectMaterial"
          :lastActiveActivityId="lastActiveActivityId"
          @click.native="selectMaterial(item)"
        />
      </div>
      <div v-else class="ml-4 text-white material-viewer-tile">&nbsp;</div>
    </b-collapse>
  </li>
</template>

<script>
import FolderIcon from '@/components/Icons/FolderIcon.vue';
import {
  blocked,
  doing,
  done,
  materialType,
  notStarted,
  rejected,
  unavailable,
} from '@/utils/constants';
import MaterialViewerTile from '@/components/Cards/MaterialViewerTile.vue';
import ProgressDone from '@/components/Icons/ProgressDone.vue';
import ProgressNotStarted from '@/components/Icons/ProgressNotStarted.vue';
import ProgressUnavailable from '@/components/Icons/ProgressUnavailable.vue';
import ProgressRejected from '@/components/Icons/ProgressRejected.vue';
import ProgressDoing from '@/components/Icons/ProgressDoing.vue';
import LockIcon from '@/components/Icons/LockIcon.vue';
import { mapActions, mapGetters } from 'vuex';
// eslint-disable-next-line import/no-self-import
import CollapsibleGroup from '@/components/CollapsibleGroup.vue';
import { deepFindActivity } from '@/utils/helpers';
import StudentService from '@/services/student.service';
import { ActivityStatuses } from '../utils/statusConstants';

export default {
  name: 'CollapsibleGroup',
  components: { FolderIcon, MaterialViewerTile },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selectMaterial: {
      type: Function,
      required: true,
    },
    lastActiveActivityId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    visible: false,
  }),
  computed: {
    ...mapGetters('courseContentStudent', ['selectedActivity', 'content']),
    isLocked() {
      return this.item.activity.statistic.is_blocked;
    },
    groupContent() {
      return this.item?.children || [];
    },
    status() {
      if (this.item.activity.statistic.is_blocked) {
        return blocked;
      }
      if (this.item.activity.statistic.status === ActivityStatuses.done) {
        return done;
      }
      if (this.item.activity.statistic.status === ActivityStatuses.rejected) {
        return rejected;
      }
      if (this.item.activity.statistic.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
    computedIcon() {
      if (this.status.value === done.value) {
        return ProgressDone;
      }
      if (this.status.value === notStarted.value) {
        return ProgressNotStarted;
      }
      if (this.status.value === unavailable.value) {
        return ProgressUnavailable;
      }
      if (this.status.value === rejected.value) {
        return ProgressRejected;
      }
      if (this.status.value === blocked.value) {
        return LockIcon;
      }
      return ProgressDoing;
    },
  },
  methods: {
    ...mapActions({
      getCourseContent: 'courseContentStudent/fetchCourseContent',
    }),
    computeComponent(item) {
      if (materialType.includes(item.activity.type)) {
        return MaterialViewerTile;
      }

      if (item.activity.type === 'group') {
        return CollapsibleGroup;
      }
      return null;
    },
    computeItem(item) {
      if (materialType.includes(item.activity.type)) {
        return item.activity;
      }

      if (item.activity.type === 'group') {
        return item;
      }
    },
    isActive(item) {
      if (this.$route.query.activityId) {
        return this.$route.query.activityId === item.activity._id;
      }
      return this.selectedActivity?._id === item.activity._id;
    },
    async openGroup() {
      if (this.isLocked) {
        return;
      }
      this.visible = !this.visible;
      if (!this.item.children || !this.item.children?.length) {
        if (this.item.activity.statistic.status !== ActivityStatuses.done) {
          await StudentService.setActivityCompleted(this.item.activity._id);
          this.refetchCourse();
        }
      }
    },
    refetchCourse() {
      const { courseId, programId } = this.$route.params;
      this.getCourseContent({ programId, courseId });
    },
  },
  watch: {
    lastActiveActivityId: {
      handler(id) {
        if (!id) {
          return;
        }
        // open collapsible
        // eslint-disable-next-line max-len
        this.visible = !!deepFindActivity(this.item, id) && !this.item.activity.statistic.is_blocked;
      },
      immediate: true,
    },
  },
};
</script>
