<template>
  <div class="question-student">
    <div class="question-student__wrapper scroll">
      <div class="question-student__complete" v-if="quiz.max_score === 0">
        <div class="header header--no-score">
          {{ $t('header.successfullyPassedQuiz') }}
        </div>
        <CompleteQuizIcon />
        <div class="description">
          {{ $t('supportText.correctlyAnsweredQuestions') }} {{ correctAnswers }}
          {{ !$device.isMobile ? questionsForm : '' }} {{ $t('supportText.of') }}
          {{ questions.length }}.
          <span v-if="questions.length !== correctAnswers">{{
            $t('supportText.LookAtTheWrongAnswers')
          }}</span>
        </div>
        <div class="question-student__action action">
          <AddButton
            class="action__back-button"
            :variant="showAttempts || quizHasAttempts ? 'secondary' : 'primary'"
            :title="$t('buttonLabels.showAnswers')"
            @click="$emit('viewAnswers')"
          />
          <div v-if="showAttempts || quizHasAttempts" class="action__reset-button">
            <AddButton :title="$t('buttonLabels.tryOneMore')" @click="restartQuiz" />
            <div class="action__label">
              <p v-if="quizHasAttempts">{{ $tc('label.attemptsLeft', attemptsLeft) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="question-student__complete" v-else>
        <div class="question-student__complete">
          <div class="header">
            {{
              quiz.statistic.status === ActivityStatuses.done
                ? $t('header.successfullyPassedQuiz')
                : $t('header.didNotPassedQuiz')
            }}
            {{ $t('header.quizScored') }}
          </div>
          <div class="count">
            <span
              :class="{
                reject: quiz.statistic.status === ActivityStatuses.rejected,
                pass: quiz.statistic.status === ActivityStatuses.done,
              }"
              >{{ quiz.statistic.score }}</span
            >
            {{ $t('supportText.of') }} {{ quiz.max_score }} {{ pointsForm(quiz.max_score) }}
            <div class="count__title" v-if="quiz.statistic.status === ActivityStatuses.rejected">
              {{ $t('label.scoreToPass') }} <span>{{ quiz.meta.passing_score }}</span>
              {{ pointsForm(quiz.meta.passing_score) }}
            </div>
          </div>
          <CompleteQuizIcon v-if="quiz.statistic.status === ActivityStatuses.done" />
          <FailedQuizIcon v-else />
          <div class="description">
            {{ $t('supportText.correctlyAnsweredQuestions') }} {{ correctAnswers }}
            {{ !$device.isMobile ? questionsForm : '' }} {{ $t('supportText.of') }}
            {{ questions.length }}.
            <span v-if="questions.length !== correctAnswers">{{
              $t('supportText.LookAtTheWrongAnswers')
            }}</span>
          </div>
          <div class="question-student__action action">
            <AddButton
              class="action__back-button"
              :variant="showScoreAttempts ? 'secondary' : 'primary'"
              :title="$t('buttonLabels.showAnswers')"
              @click="$emit('viewAnswers')"
            />
            <div v-if="showScoreAttempts" class="action__reset-button">
              <AddButton :title="$t('buttonLabels.tryOneMore')" @click="restartQuiz" />
              <div class="action__label">
                <p v-if="quizHasAttempts">{{ $tc('label.attemptsLeft', attemptsLeft) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CompleteQuizIcon from '@/components/Icons/CompleteQuizIcon.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import deviceMixin from '@/mixins/device.mixin';
import FailedQuizIcon from '@/components/Icons/FailedQuizIcon.vue';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'ResultQuiz',
  props: {
    restartQuiz: {
      type: Function,
      required: true,
    },
  },
  components: { FailedQuizIcon, CompleteQuizIcon, AddButton },
  mixins: [deviceMixin],
  data: () => ({
    ActivityStatuses,
  }),
  computed: {
    ...mapGetters('courseContentStudent', ['questions', 'selectedActivity']),
    ...mapGetters({ quiz: 'courseContentStudent/selectedActivity' }),
    questionsForm() {
      if (this.$i18n?.locale === 'en') {
        return 'questions';
      }
      const preLastDigit = Math.trunc((this.correctAnswers % 100) / 10);

      if (preLastDigit === 1) {
        return 'вопросов';
      }

      switch (this.correctAnswers % 10) {
        case 1:
          return 'вопрос';
        case 2:
        case 3:
        case 4:
          return 'вопроса';
        default:
          return 'вопросов';
      }
    },
    correctAnswers() {
      const qs = this.questions;
      let missedQuestions = 0;
      qs.map((question) => {
        if (question.statistic.meta) {
          if (question.statistic.meta.answers?.find((x) => x.is_correct !== x.is_selected)) {
            missedQuestions += 1;
          }
        }
        return question;
      });
      return qs.length - missedQuestions;
    },
    quizHasAttempts() {
      return this.quiz.meta?.attempts > 0 && this.attemptsLeft > 0;
    },
    showAttempts() {
      return this.quiz.meta?.attempts === 0;
    },
    attemptsLeft() {
      return this.quiz?.meta?.attempts - (this.quiz?.statistic?.meta?.used_attempts || 1);
    },
    showScoreAttempts() {
      return (this.quiz.statistic.status === ActivityStatuses.rejected
      && (this.showAttempts || this.quizHasAttempts))
      || (this.quiz.max_score !== 0
          && this.quiz.statistic.score < this.quiz.max_score
          && (this.showAttempts || this.quizHasAttempts));
    },
  },
  methods: {
    pointsForm(points) {
      return this.$tc('label.pointsPluralRod', points);
    },
  },
};
</script>
