<template>
  <li class="material-viewer-tile" :class="{ locked: isLocked }">
    <div class="material-viewer-tile__left">
      <div class="material-viewer-tile__icon">
        <component :is="iconComponent" />
      </div>
      <div class="material-viewer-tile__title">
        <span>{{ index + 1 }}.&nbsp;{{ item.name }}</span>
        <span v-if="item.max_score > 0"
          >{{ item.statistic.score }}/{{ item.max_score }}
          {{ $tc('label.pointsPluralRod', item.max_score) }}</span
        >
      </div>
    </div>
    <div class="material-viewer-tile__right status">
      <div class="status__icon">
        <transition name="component-fade" mode="out-in">
          <component :is="computedIcon" :key="item.statistic.status" />
        </transition>
      </div>
    </div>
  </li>
</template>

<script>
import constantsMixin from '@/mixins/constants.mixin';
import PptxIcon from '@/components/Icons/PptxIcon.vue';
import PdfIcon from '@/components/Icons/PdfIcon.vue';
import DocxIcon from '@/components/Icons/DocxIcon.vue';
import ProgressNotStarted from '@/components/Icons/ProgressNotStarted.vue';
import ProgressDone from '@/components/Icons/ProgressDone.vue';
import ProgressUnavailable from '@/components/Icons/ProgressUnavailable.vue';
import ProgressDoing from '@/components/Icons/ProgressDoing.vue';
import ProgressRejected from '@/components/Icons/ProgressRejected.vue';
import {
  blocked, doing, done, notStarted, rejected, unavailable,
} from '@/utils/constants';
import QuizIcon from '@/components/Icons/QuizIcon.vue';
import VideoIcon from '@/components/Icons/VideoIcon.vue';
import LockIcon from '@/components/Icons/LockIcon.vue';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'MaterialViewerTile',
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  mixins: [constantsMixin],
  computed: {
    iconComponent() {
      if (this.item.type === 'quiz' || this.item.type === 'task') return QuizIcon;
      if (this.item.meta?.fileExtension === this.pptx) return PptxIcon;
      if (this.item.meta?.fileExtension === this.pdf) return PdfIcon;
      if (this.item.meta?.fileExtension === this.docx) return DocxIcon;
      return VideoIcon;
    },
    isLocked() {
      return this.item.statistic.is_blocked;
    },
    status() {
      if (this.item.statistic.is_blocked) {
        return blocked;
      }
      if (this.item.statistic.status === ActivityStatuses.done) {
        return done;
      }
      if (this.item.statistic.status === ActivityStatuses.rejected) {
        return rejected;
      }
      if (this.item.statistic.status === ActivityStatuses.doing) {
        return doing;
      }
      return notStarted;
    },
    computedIcon() {
      if (this.status.value === done.value) {
        return ProgressDone;
      }
      if (this.status.value === notStarted.value) {
        return ProgressNotStarted;
      }
      if (this.status.value === unavailable.value) {
        return ProgressUnavailable;
      }
      if (this.status.value === rejected.value) {
        return ProgressRejected;
      }
      if (this.status.value === blocked.value) {
        return LockIcon;
      }
      return ProgressDoing;
    },
  },
};
</script>
