<template>
  <div class="question-student scroll">
    <div v-if="question" class="question-student__wrapper">
      <div class="question-student__header">
        <div class="question-student__question-counter">
          <div class="question-counter">
            <div class="question-counter__counter">
              {{ $t('label.question') }} {{ currentQuestionIndex + 1 }}/{{ questionsQuantity }}
            </div>
            <div v-if="isAttemptsShown" class="question-counter__attempt">
              {{ $t('label.attempt') }}
              {{ attemptCounter }}
            </div>
          </div>
        </div>
        <div class="question-student__name">{{ questionName }}</div>
      </div>

      <ul class="question-student__body">
        <li v-for="answer of question.meta.answers" :key="answer.id">
          <AnswerTileStudent
            :answer="answer"
            :isMultipleQuestion="question.meta.is_multiple"
            :answerState="answerState(answer)"
            :isSending="isSending"
            :isQuestionCompleted="isQuestionCompleted"
            @change="handleAnswer"
          />
        </li>
      </ul>

      <div class="question-student__action">
        <div class="question-student__left-action-button">
          <transition name="component-fade" mode="out-in">
            <AddButton
              v-if="currentQuestionIndex !== 0"
              :title="$t('buttonLabels.previousQuestion')"
              variant="secondary"
              @click="previousQuestion"
            />
          </transition>
        </div>
        <div class="question-student__right-action-button">
          <transition name="component-fade" mode="out-in">
            <AddButton
              v-if="isShowResult"
              :title="$t('buttonLabels.viewResult')"
              variant="primary"
              @click="showResult"
            />
            <AddButton
              v-else-if="!isQuestionCompleted && !isSending"
              :title="$t('buttonLabels.sendSelected')"
              variant="secondary"
              :isDisabled="chosenAnswersIds.length === 0"
              @click="submitAnswers"
            />
            <AddButton
              v-else-if="isQuestionCompleted && !isSending"
              :title="$t('buttonLabels.nextQuestion')"
              variant="primary"
              @click="nextQuestion"
            />
          </transition>
        </div>
      </div>
    </div>
    <EmptyQuiz v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddButton from '@/components/Buttons/AddButton.vue';
import AnswerTileStudent from '@/components/Cards/AnswerTileStudent.vue';
import EmptyQuiz from '@/components/Cards/EmptyQuiz.vue';
import ActivityTimeMetrics from '@/utils/activityTimeMetrics';
import { ActivityStatuses } from '../../utils/statusConstants';

export default {
  name: 'QuestionCardStudent',
  components: { AnswerTileStudent, AddButton, EmptyQuiz },
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    question: {
      type: Object,
      default: null,
    },
    currentQuestionIndex: {
      type: Number,
      required: true,
    },
    questionsQuantity: {
      type: Number,
      required: true,
    },
    nextQuestion: {
      type: Function,
      required: true,
    },
    previousQuestion: {
      type: Function,
      required: true,
    },
    sendAnswers: {
      type: Function,
      required: true,
    },
    showResult: {
      type: Function,
      required: true,
    },
    quizStatisticId: {
      type: String,
      required: true,
    },
    quizId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    chosenAnswersIds: [],
    isSending: false,
    timeMetrics: null,
  }),
  computed: {
    ...mapGetters('courseContentStudent', ['isQuizCompleted']),
    isShowResult() {
      return (
        this.isQuizCompleted
        && this.questionsQuantity - 1 === this.currentQuestionIndex
        && !this.isSending
      );
    },
    isQuestionCompleted() {
      // eslint-disable-next-line max-len
      return this.question?.statistic.status === ActivityStatuses.done;
    },
    questionName() {
      return this.question?.name;
    },
    attemptCounter() {
      const maxAttempt = this.quiz.meta?.attempts;
      return `${this.usedAttempts}/${maxAttempt}`;
    },
    isAttemptsShown() {
      return this.quiz.meta?.attempts >= 1;
    },
    usedAttempts() {
      return this.quiz?.statistic?.meta?.used_attempts || 1;
    },
  },
  mounted() {
    if (this.question) {
      this.timeMetrics = new ActivityTimeMetrics(this.question);
    }
  },
  methods: {
    ...mapActions('courseContentStudent', ['setActivityAsCompleted', 'setSyncActivityAsStarted']),
    ...mapActions('toaster', ['setToaster']),
    ...mapActions({ startQuiz: 'courseContentStudent/restartQuiz' }),
    async submitAnswers() {
      // Set quiz as started if it's a first question and quiz does not have stat
      if (this.currentQuestionIndex === 0 && !this.quiz?.statistic?.meta) {
        await this.startQuiz({
          quizId: this.quizId,
        });
      }

      if (!this.chosenAnswersIds.length) {
        return;
      }

      this.isSending = true;
      await this.setStatistic()
        .then(() => {
          this.sendAnswers({
            question: this.question,
            selectedAnswers: this.chosenAnswersIds,
          })
            .then(this.checkForCompletionAndRefetch)
            .catch((e) => {
              if (e.response?.status === 404) {
                this.setToaster({
                  type: 'toast-warning',
                  toast: {
                    title: this.$t('errorMessages.questionNotFound'),
                    body: this.$t('errorMessages.reloadPageForContinue'),
                    button: {
                      action: () => window.location.reload(),
                      text: this.$t('supportText.reloadPage'),
                    },
                  },
                });
              }
            });
        })
        .catch(console.log);

      this.isSending = false;
    },
    answerState(answer) {
      if (!this.isQuestionCompleted) {
        return this.chosenAnswersIds.find((id) => id === answer.id) ? 'selected' : '';
      }

      const foundAnswer = this.question.statistic?.meta?.answers?.find((a) => a.id === answer.id);
      if (!foundAnswer) {
        return 'disabled';
      }

      if (!foundAnswer?.is_selected && foundAnswer?.is_correct) {
        return 'missed-correct';
      }

      if (foundAnswer?.is_correct) {
        return 'valid';
      }

      if (foundAnswer.is_correct !== undefined && !foundAnswer.is_correct) {
        return 'invalid';
      }
    },
    handleAnswer(answerId) {
      if (!this.question.meta.is_multiple) {
        return (this.chosenAnswersIds = [answerId]);
      }

      const foundIndex = this.chosenAnswersIds.findIndex((id) => id === answerId);
      if (foundIndex !== -1) {
        this.chosenAnswersIds.splice(foundIndex, 1);
      } else {
        this.chosenAnswersIds.push(answerId);
      }
    },
    checkForCompletionAndRefetch() {
      if (this.currentQuestionIndex === this.questionsQuantity - 1) {
        this.$store.dispatch('courseContentStudent/fetchCourseContent');
      }
    },
    async setStatistic() {
      if (this.currentQuestionIndex === 0) {
        await this.setSyncActivityAsStarted(this.quizId);
      }
    },
  },
  watch: {
    currentQuestionIndex: {
      handler() {
        this.chosenAnswersIds = [];
        this.timeMetrics.updateActivity(this.question);
      },
    },
  },
  beforeDestroy() {
    if (this.timeMetrics && this.timeMetrics.destroy) {
      this.timeMetrics.destroy();
    }
  },
};
</script>
